var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (root, factory) {
  if (exports) {
    exports = factory();
  } else {
    root.SimpleLightbox = factory();
  }
})(exports, function () {
  function assign(target) {
    for (var i = 1; i < arguments.length; i++) {
      var obj = arguments[i];

      if (obj) {
        for (var key in obj) {
          obj.hasOwnProperty(key) && (target[key] = obj[key]);
        }
      }
    }

    return target;
  }

  function addClass(element, className) {
    if (element && className) {
      element.className += " " + className;
    }
  }

  function removeClass(element, className) {
    if (element && className) {
      element.className = element.className.replace(new RegExp("(\\s|^)" + className + "(\\s|$)"), " ").trim();
    }
  }

  function parseHtml(html) {
    var div = document.createElement("div");
    div.innerHTML = html.trim();
    return div.childNodes[0];
  }

  function matches(el, selector) {
    return (el.matches || el.matchesSelector || el.msMatchesSelector).call(el, selector);
  }

  function getWindowHeight() {
    return "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
  }

  function SimpleLightbox(options) {
    (this || _global).init.apply(this || _global, arguments);
  }

  SimpleLightbox.defaults = {
    // add custom classes to lightbox elements
    elementClass: "",
    elementLoadingClass: "slbLoading",
    htmlClass: "slbActive",
    closeBtnClass: "",
    nextBtnClass: "",
    prevBtnClass: "",
    loadingTextClass: "",
    // customize / localize controls captions
    closeBtnCaption: "Close",
    nextBtnCaption: "Next",
    prevBtnCaption: "Previous",
    loadingCaption: "Loading...",
    bindToItems: true,
    // set click event handler to trigger lightbox on provided $items
    closeOnOverlayClick: true,
    closeOnEscapeKey: true,
    nextOnImageClick: true,
    showCaptions: true,
    captionAttribute: "title",
    // choose data source for library to glean image caption from
    urlAttribute: "href",
    // where to expect large image
    startAt: 0,
    // start gallery at custom index
    loadingTimeout: 100,
    // time after loading element will appear
    appendTarget: "body",
    // append elsewhere if needed
    beforeSetContent: null,
    // convenient hooks for extending library behavoiur
    beforeClose: null,
    afterClose: null,
    beforeDestroy: null,
    afterDestroy: null,
    videoRegex: new RegExp(/youtube.com|vimeo.com/) // regex which tests load url for iframe content

  };
  assign(SimpleLightbox.prototype, {
    init: function (options) {
      options = (this || _global).options = assign({}, SimpleLightbox.defaults, options);
      var self = this || _global;
      var elements;

      if (options.$items) {
        elements = options.$items.get();
      }

      if (options.elements) {
        elements = [].slice.call(typeof options.elements === "string" ? document.querySelectorAll(options.elements) : options.elements);
      }

      (this || _global).eventRegistry = {
        lightbox: [],
        thumbnails: []
      };
      (this || _global).items = [];
      (this || _global).captions = [];

      if (elements) {
        elements.forEach(function (element, index) {
          self.items.push(element.getAttribute(options.urlAttribute));
          self.captions.push(element.getAttribute(options.captionAttribute));

          if (options.bindToItems) {
            self.addEvent(element, "click", function (e) {
              e.preventDefault();
              self.showPosition(index);
            }, "thumbnails");
          }
        });
      }

      if (options.items) {
        (this || _global).items = options.items;
      }

      if (options.captions) {
        (this || _global).captions = options.captions;
      }
    },
    addEvent: function (element, eventName, callback, scope) {
      (this || _global).eventRegistry[scope || "lightbox"].push({
        element: element,
        eventName: eventName,
        callback: callback
      });

      element.addEventListener(eventName, callback);
      return this || _global;
    },
    removeEvents: function (scope) {
      (this || _global).eventRegistry[scope].forEach(function (item) {
        item.element.removeEventListener(item.eventName, item.callback);
      });

      (this || _global).eventRegistry[scope] = [];
      return this || _global;
    },
    next: function () {
      return this.showPosition((this || _global).currentPosition + 1);
    },
    prev: function () {
      return this.showPosition((this || _global).currentPosition - 1);
    },
    normalizePosition: function (position) {
      if (position >= (this || _global).items.length) {
        position = 0;
      } else if (position < 0) {
        position = (this || _global).items.length - 1;
      }

      return position;
    },
    showPosition: function (position) {
      var newPosition = this.normalizePosition(position);

      if (typeof (this || _global).currentPosition !== "undefined") {
        (this || _global).direction = newPosition > (this || _global).currentPosition ? "next" : "prev";
      }

      (this || _global).currentPosition = newPosition;
      return this.setupLightboxHtml().prepareItem((this || _global).currentPosition, (this || _global).setContent).show();
    },
    loading: function (on) {
      var self = this || _global;
      var options = (this || _global).options;

      if (on) {
        (this || _global).loadingTimeout = setTimeout(function () {
          addClass(self.$el, options.elementLoadingClass);
          self.$content.innerHTML = "<p class=\"slbLoadingText " + options.loadingTextClass + "\">" + options.loadingCaption + "</p>";
          self.show();
        }, options.loadingTimeout);
      } else {
        removeClass((this || _global).$el, options.elementLoadingClass);
        clearTimeout((this || _global).loadingTimeout);
      }
    },
    prepareItem: function (position, callback) {
      var self = this || _global;
      var url = (this || _global).items[position];
      this.loading(true);

      if ((this || _global).options.videoRegex.test(url)) {
        callback.call(self, parseHtml("<div class=\"slbIframeCont\"><iframe class=\"slbIframe\" frameborder=\"0\" allowfullscreen src=\"" + url + "\"></iframe></div>"));
      } else {
        var $imageCont = parseHtml("<div class=\"slbImageWrap\"><img class=\"slbImage\" src=\"" + url + "\" /></div>");
        (this || _global).$currentImage = $imageCont.querySelector(".slbImage");

        if ((this || _global).options.showCaptions && (this || _global).captions[position]) {
          $imageCont.appendChild(parseHtml("<div class=\"slbCaption\">" + (this || _global).captions[position] + "</div>"));
        }

        this.loadImage(url, function () {
          self.setImageDimensions();
          callback.call(self, $imageCont);
          self.loadImage(self.items[self.normalizePosition(self.currentPosition + 1)]);
        });
      }

      return this || _global;
    },
    loadImage: function (url, callback) {
      if (!(this || _global).options.videoRegex.test(url)) {
        var image = new Image();
        callback && (image.onload = callback);
        image.src = url;
      }
    },
    setupLightboxHtml: function () {
      var o = (this || _global).options;

      if (!(this || _global).$el) {
        (this || _global).$el = parseHtml("<div class=\"slbElement " + o.elementClass + "\">" + "<div class=\"slbOverlay\"></div>" + "<div class=\"slbWrapOuter\">" + "<div class=\"slbWrap\">" + "<div class=\"slbContentOuter\">" + "<div class=\"slbContent\"></div>" + "<button type=\"button\" title=\"" + o.closeBtnCaption + "\" class=\"slbCloseBtn " + o.closeBtnClass + "\">\xD7</button>" + ((this || _global).items.length > 1 ? "<div class=\"slbArrows\">" + "<button type=\"button\" title=\"" + o.prevBtnCaption + "\" class=\"prev slbArrow" + o.prevBtnClass + "\">" + o.prevBtnCaption + "</button>" + "<button type=\"button\" title=\"" + o.nextBtnCaption + "\" class=\"next slbArrow" + o.nextBtnClass + "\">" + o.nextBtnCaption + "</button>" + "</div>" : "") + "</div>" + "</div>" + "</div>" + "</div>");
        (this || _global).$content = (this || _global).$el.querySelector(".slbContent");
      }

      (this || _global).$content.innerHTML = "";
      return this || _global;
    },
    show: function () {
      if (!(this || _global).modalInDom) {
        document.querySelector((this || _global).options.appendTarget).appendChild((this || _global).$el);
        addClass(document.documentElement, (this || _global).options.htmlClass);
        this.setupLightboxEvents();
        (this || _global).modalInDom = true;
      }

      return this || _global;
    },
    setContent: function (content) {
      var $content = typeof content === "string" ? parseHtml(content) : content;
      this.loading(false);
      this.setupLightboxHtml();
      removeClass((this || _global).$content, "slbDirectionNext");
      removeClass((this || _global).$content, "slbDirectionPrev");

      if ((this || _global).direction) {
        addClass((this || _global).$content, (this || _global).direction === "next" ? "slbDirectionNext" : "slbDirectionPrev");
      }

      if ((this || _global).options.beforeSetContent) {
        (this || _global).options.beforeSetContent($content, this || _global);
      }

      (this || _global).$content.appendChild($content);

      return this || _global;
    },
    setImageDimensions: function () {
      if ((this || _global).$currentImage) {
        (this || _global).$currentImage.style.maxHeight = getWindowHeight() + "px";
      }
    },
    setupLightboxEvents: function () {
      var self = this || _global;

      if ((this || _global).eventRegistry.lightbox.length) {
        return this || _global;
      }

      this.addEvent((this || _global).$el, "click", function (e) {
        var $target = e.target;

        if (matches($target, ".slbCloseBtn") || self.options.closeOnOverlayClick && matches($target, ".slbWrap")) {
          self.close();
        } else if (matches($target, ".slbArrow")) {
          matches($target, ".next") ? self.next() : self.prev();
        } else if (self.options.nextOnImageClick && self.items.length > 1 && matches($target, ".slbImage")) {
          self.next();
        }
      }).addEvent(document, "keyup", function (e) {
        self.options.closeOnEscapeKey && e.keyCode === 27 && self.close();

        if (self.items.length > 1) {
          (e.keyCode === 39 || e.keyCode === 68) && self.next();
          (e.keyCode === 37 || e.keyCode === 65) && self.prev();
        }
      }).addEvent(window, "resize", function () {
        self.setImageDimensions();
      });
      return this || _global;
    },
    close: function () {
      if ((this || _global).modalInDom) {
        this.runHook("beforeClose");
        this.removeEvents("lightbox");
        (this || _global).$el && (this || _global).$el.parentNode.removeChild((this || _global).$el);
        removeClass(document.documentElement, (this || _global).options.htmlClass);
        (this || _global).modalInDom = false;
        this.runHook("afterClose");
      }

      (this || _global).direction = undefined;
      (this || _global).currentPosition = (this || _global).options.startAt;
    },
    destroy: function () {
      this.close();
      this.runHook("beforeDestroy");
      this.removeEvents("thumbnails");
      this.runHook("afterDestroy");
    },
    runHook: function (name) {
      (this || _global).options[name] && (this || _global).options[name](this || _global);
    }
  });

  SimpleLightbox.open = function (options) {
    var instance = new SimpleLightbox(options);
    return options.content ? instance.setContent(options.content).show() : instance.showPosition(instance.options.startAt);
  };

  SimpleLightbox.registerAsJqueryPlugin = function ($) {
    $.fn.simpleLightbox = function (options) {
      var lightboxInstance;
      var $items = this || _global;
      return this.each(function () {
        if (!$.data(this || _global, "simpleLightbox")) {
          lightboxInstance = lightboxInstance || new SimpleLightbox($.extend({}, options, {
            $items: $items
          }));
          $.data(this || _global, "simpleLightbox", lightboxInstance);
        }
      });
    };

    $.SimpleLightbox = SimpleLightbox;
  };

  if (typeof window !== "undefined" && window.jQuery) {
    SimpleLightbox.registerAsJqueryPlugin(window.jQuery);
  }

  return SimpleLightbox;
});

export default exports;